























































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import moment from 'moment';
import _, { isString } from 'lodash';
import qs from 'qs';
import { Context } from '@nuxt/types';
import { ResponseCode } from '~/util/constant';
import { $cookies } from '~/util/api';
import { checkDevice, adminHost, handleNewsUrl } from '~/util/common';
import GoodsItem from '~/components/Goods/item.vue';
import { TraceCode } from '~/util/trace';

export default Vue.extend({
  filters: {
    sectionTitle(tags: Record<string, any>[], suffixText: string) {
      console.log('677-', tags);
      let result = '';
      const secondIndustry = tags.find(
        (item) => item.type === 'secondIndustry'
      );

      if (secondIndustry?.name) {
        result = `${secondIndustry.name}${suffixText}`;
      }
      // 地区名称(地区名称+城市名称)
      let areaListRes = tags.filter((item) => item.type === 'province');
      console.log('716-', areaListRes);
      let areaName = '';
      if (areaListRes.length > 0) {
        areaListRes.map((i: any) => {
          areaName += i.name;
        });
      }
      return areaName + result || suffixText;
    },
    description(item: Record<string, any>): any {
      const content = item.description || item.content || '';

      const reg = /<[^>]+>/gi;
      return content
        .replace(reg, '')
        .replace(/&nbsp;/g, '')
        .trim();
    },

    brandName(tags: any[]): string {
      const obj: Record<string, any> = {};
      tags.forEach((tag) => {
        obj[tag.type] = tag.name;
      });

      let name = '';

      if (obj.date) {
        name += obj.date;
      }

      if (obj.province) {
        name += obj.province;
      } else if (obj.area) {
        name += obj.area;
      }

      if (obj.secondIndustry) {
        name += obj.secondIndustry;
      } else if (obj.firstIndustry) {
        name += obj.firstIndustry;
      }

      return `${name}`;
    },
    firstLink(val: string): string {
      const [firstIndustryId, secondIndustryId, area, province, city, date] =
        val.split('-').slice(1, 7);

      if (
        firstIndustryId === '0' &&
        secondIndustryId === '0' &&
        area === '0' &&
        province === '0' &&
        city === '0' &&
        date === '0'
      ) {
        return '/exhibition/';
      } else {
        return val;
      }
    },
  },
  components: { GoodsItem },
  // 校验params参数
  validate(ctx: Context) {
    const { path } = ctx.route;
    const { keyword } = ctx.query;
    if (isString(keyword)) {
      // 搜索词不能包含 网址、網纸；只能输入字母和汉字、搜索词长度必须小于等于 50
      return (
        !/网址|網纸/.test(keyword) &&
        /^[0-9a-zA-Z\u4E00-\u9FA5 \(\)（）]+$/.test(keyword) &&
        keyword.length <= 50
      );
    } else {
      if (path === '/exhibition' || path === '/exhibition/') {
        return true;
      }
      const _validate =
        /exhibition-(\d+)-(\d+)-(\d+)-(\d+)-(\d+)-(\d+)-(\d+)/.test(path);

      return _validate;
    }
  },
  async asyncData(ctx) {
    try {
      const { keyword, sort, type: sortType } = ctx.query;
      console.log('818-keyword搜索', keyword);
      const lang = _.get(ctx, 'query.lang', null);
      let langApi = 'cn';
      if (lang == 'zh') langApi = 'cn';
      if (lang == 'en') langApi = 'en';

      const {
        f: firstIndustryId = '0', // 一级行业id
        s: secondIndustryId = 0, // 二级行业id
        g: area = 0, // 地区id(1国内, 0国际) 洲
        p: province = 0, // 省份(国内), 国家(国际)
        c: city = 0, // 城市
        t: date = '0', // 时间
        page = 1,
      } = ctx.params;

      /**
       * 重定向 原来广州与省份同级，现在要重定向到广东-广州
       * 广州604 重定向到 广东528，广州1250
       * 深圳520  重定向到 广东528，深圳1252
       * 宁夏银川 1046  重定向到 宁夏1570，银川1571
       * 拉萨 1562  重定向 西藏1572  ，拉萨1352
       *
       * */
      if (province == '604') {
        let newUrl: any = `/exhibition-${firstIndustryId}-${secondIndustryId}-1-528-1250-${date}-1`;
        ctx.redirect('301', newUrl);
        throw new Error(
          `/exhibition-${firstIndustryId}-${secondIndustryId}-1-528-1250-${date}-1`
        );
      }
      if (province == '520') {
        let newUrl: any = `/exhibition-${firstIndustryId}-${secondIndustryId}-1-528-1252-${date}-1`;
        ctx.redirect('301', newUrl);
        throw new Error(
          `/exhibition-${firstIndustryId}-${secondIndustryId}-1-528-1252-${date}-1`
        );
      }
      if (province == '1046') {
        let newUrl: any = `/exhibition-${firstIndustryId}-${secondIndustryId}-1-1570-1571-${date}-1`;
        ctx.redirect('301', newUrl);
        throw new Error(
          `/exhibition-${firstIndustryId}-${secondIndustryId}-1-1570-1571-${date}-1`
        );
      }
      if (province == '1562') {
        let newUrl: any = `/exhibition-${firstIndustryId}-${secondIndustryId}-1-1572-1352-${date}-1`;
        ctx.redirect('301', newUrl);
        throw new Error(
          `/exhibition-${firstIndustryId}-${secondIndustryId}-1-1572-1352-${date}-1`
        );
      }

      const pageNum = isNaN(+page) ? 1 : +page;
      const pageSize = 12;
      let isRecommond = false; // 是否显示行业推荐, 默认不显示

      let type = '0'; // 国内
      if (+area === 1) {
        type = '0';
      } else {
        type = '1';
      }

      // 排序
      const sortObj: Record<string, any> = {};
      if (sort === 'scale' && sortType) {
        sortObj.sortScale = sortType;
      } else if (sort === 'hot' && sortType) {
        sortObj.sortHot = sortType;
      } else if (sort === 'start_time' && sortType) {
        sortObj.sortTime = sortType;
      }
      // 搜索列表
      let listParams: Record<string, any> = {
        locale: langApi,
        pageNum,
        pageSize,
        regionType: type,
        selectTimeType: 1,
        // regionId, // 洲id
        // cityId   // 城市id
        // sortStr: sort,
        // sortType,
        // time: moment().format('YYYY-MM-DD'),
      };
      listParams = Object.assign(listParams, sortObj);

      // 行业推荐
      let hotParams: Record<string, any> = {
        locale: langApi,
        pageNum: 1,
        pageSize: 8,
        regionType: type,
        recommond: '2',
        selectTimeType: 1, // 0:查询当月的 1:查询给定时间之后的
      };
      hotParams = Object.assign(hotParams, sortObj);

      let requestList: any[] = [];

      if (+secondIndustryId > 0) {
        isRecommond = true; // 二级行业有值, 显示行业推荐
        listParams.industryId = secondIndustryId || 0;
        hotParams.industryId = secondIndustryId || 0;
        ctx.store.dispatch('global/getLinks', { id: secondIndustryId });

        requestList = [
          ctx.$axios.post('/api/exhibition/list', hotParams), // 行业推荐
        ];
      } else {
        if (+firstIndustryId > 0) {
          listParams.industryId = firstIndustryId || 0;
          ctx.store.dispatch('global/getLinks', { id: firstIndustryId });
        }
      }
      let newsRequestList: any[] = [];
      console.log('882-', province, Number(province) > 0);
      // 地区行业资讯
      if (+secondIndustryId > 0 || Number(province) > 0) {
        let newsParams: any = {
          locale: langApi,
          pageNum: 1,
          pageSize: 7,
        };

        if (+secondIndustryId > 0) newsParams['industryId'] = secondIndustryId;
        if (Number(province) > 0) newsParams['areaId'] = province;
        console.log('892-', +secondIndustryId > 0, newsParams);
        newsRequestList.push(
          ctx.$axios.post('/api/newsApi/homePageList', newsParams)
        );
      }

      if (area > 1) {
        listParams.area = area;
        listParams['regionId'] = area;
      }

      if (province > 0) {
        listParams.area = province;
      }
      if (city > 0) {
        listParams['cityId'] = city;
      }

      if (+date > 0) {
        const dateTemp = `${date.slice(0, 4)}-${date.slice(4)}-01`;
        listParams.time = moment(new Date(dateTemp)).format('YYYY-MM-DD');
        if (listParams.time === 'Invalid date') {
          return ctx.error({
            message: 'Page not found',
            path: ctx.route.fullPath,
            statusCode: 404,
          });
        }
      }

      let queryListByKeyWordParams = {};
      if (keyword) {
        queryListByKeyWordParams = {
          pageNum,
          pageSize: 12,
          keyWords: keyword,
          locale: langApi,
        };
        Object.assign(queryListByKeyWordParams, sortObj);
      }

      let areaType: any = ' ';
      if (area === '1') {
        areaType = 0;
      } else {
        areaType = 1;
      }

      requestList = [
        ctx.$axios.post('/api/bannerApi/page', {
          type: 4,
          channel: 0,
          industry1: firstIndustryId,
          industry2: secondIndustryId,
          areaType,
        }), // 首页Banner
        ctx.$axios.post('/api/newsApi/homePageList', {
          locale: langApi,
          // newsType: 1,
          pageNum: 1,
          pageSize: 20,
        }), // 资讯列表
        ctx.$axios.get(`/api/industry/getAll?locale=${langApi}`),
        ctx.$axios.get(`/api/exhibition/city/${type}`),
        keyword
          ? ctx.$axios.post(
              '/api/exhibition/queryListByKeyWord',
              queryListByKeyWordParams
            )
          : ctx.$axios.post('/api/exhibition/list', listParams),
        ...newsRequestList,
        ...requestList,
      ];
      console.log('964-', requestList);

      const result: any[] = await Promise.all(requestList);
      const [
        bannerRes,
        newsListRes, // 最新资讯
        industryRes,
        areaRes,
        fairRes,
        newsRes, // 地区行业资讯
        hotFairRes,
      ] = result;

      let newsList = [];
      console.log('978-最新资讯', newsListRes);
      if (newsListRes.code === ResponseCode.SUCCESS) {
        newsList = newsListRes.data.records.map(
          (item: {
            newsId: any;
            viewUrl: any;
            newsName: any;
            publishTime: any;
          }) => {
            const isToday = moment().diff(moment(item.publishTime), 'days');
            const format = 'HH:mm';

            return {
              newsId: item.newsId,
              viewUrl: item.viewUrl,
              newsName: item.newsName,
              createTime: moment(item.publishTime).format(format),
            };
          }
        );
      }

      const banner = [];
      if (bannerRes?.code === ResponseCode.SUCCESS) {
        for (let i = 0; i < bannerRes.data.length; i++) {
          const url = bannerRes.data[i].bannerUrlList;
          const link = bannerRes.data[i].bannerLinkListPc;
          banner.push({
            url,
            link,
          });
        }
        // console.log(firstIndustryId);
        // console.log(secondIndustryId);
        // console.log(areaType);
        // console.log(banner);
      }
      let industry = [];
      if (industryRes?.code === ResponseCode.SUCCESS) {
        industry = industryRes.data;
      }

      let areas = [];
      console.log('920-地区', areaRes);
      if (areaRes?.code === ResponseCode.SUCCESS) {
        areas = areaRes.data;
      }

      let fair = {};
      console.log('923-列表', fairRes);
      if (fairRes?.code === ResponseCode.SUCCESS) {
        fair = fairRes.data;

        if (pageNum > 1 && pageNum > fairRes.data.pages) {
          return ctx.error({
            message: 'Page not found',
            path: ctx.route.fullPath,
            statusCode: 404,
          });
        }
      }

      let hotFair = {};
      if (hotFairRes?.code === ResponseCode.SUCCESS) {
        hotFair = hotFairRes.data;
      }

      const news = [];
      console.log('1044-资讯', newsRes);
      if (newsRes?.code === ResponseCode.SUCCESS) {
        for (let index = 0; index < newsRes.data.records.length; index++) {
          const item = newsRes.data.records[index];
          const description =
            index === 0
              ? await ctx.$axios.get(`/ossApi/newsContent/${item.newsId}.html`)
              : '';
          news.push({
            newsId: item.newsId,
            viewUrl: item.viewUrl,
            newsName: item.newsName,
            logo: item.logo,
            industryName: item.industryName,
            industryId: item.industryId,
            createTime: moment(item.publishTime).format('MM-DD HH:mm:ss'),
            views: item.views,
            description: typeof description == 'string' ? description : '',
            specialTopics: item.specialTopics,
          });
        }
      }

      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();
      const dateList: any[] = [];

      for (let i = 0; i < 12; i++) {
        const date = moment().set({
          year: currentYear,
          month: currentMonth + i,
        });
        dateList.push({
          label: date.format('YYYY年MM月'),
          value: date.format('YYYYMM'),
          value2: date.format('YYYY-MM'),
        });
      }

      // 检查筛选条件展会数据
      // 如果勾选了二级行业id,则检查二级行业
      let checkIndustry = (v: any) => {
        return +secondIndustryId > 0 ? v == secondIndustryId : true;
      };
      let checkRegion = (v: any) => {
        return +area == 1 ? v == secondIndustryId : true;
      };
      // 勾选时间
      let checkDate = (v: any) => {
        let timeStr =
          +date > 0 ? date.slice(0, 4) + '-' + date.slice(4, 6) : '--';
        console.log('1180-', timeStr);
        return +date > 0 ? v.includes(timeStr) : true;
      };

      // 已选条件
      const checkTags = [];
      let secondIndustry = [];
      let cityList = [];
      if (+firstIndustryId > 0) {
        // 已选一级行业
        const item = industry.find(
          (item: any) => item.industryId == firstIndustryId
        );
        secondIndustry = item?.children; // 二级行业
        checkTags.push({
          name: item?.industryName,
          type: 'firstIndustry',
          id: firstIndustryId,
        });
      }
      if (secondIndustryId > 0) {
        // 已选二级行业
        const item = secondIndustry?.find(
          (item: any) => item.id == secondIndustryId
        );
        if (!item) {
          return ctx.error({
            message: 'Page not found',
            path: ctx.route.fullPath,
            statusCode: 404,
          });
        }

        checkTags.push({
          name: item?.industryName,
          type: 'secondIndustry',
          id: secondIndustryId,
          pid: firstIndustryId,
        });
      }
      if (area > 0) {
        const item = areas.find((item: any) => item.id == area);
        if (!item) {
          return ctx.error({
            message: 'Page not found',
            path: ctx.route.fullPath,
            statusCode: 404,
          });
        }
        cityList = item?.areas;

        checkTags.push({
          name: item?.regionName,
          type: 'area',
          id: item?.id,
        });
      }

      let provinceCityList = []; // 当前选中省份的城市列表

      if (+province > 0) {
        const item = cityList.find((item: any) => item.id == province);

        provinceCityList =
          cityList.find((i: any) => province == i.id).areas || []; // 国外城市列表可能为空

        if (!item) {
          return ctx.error({
            message: 'Page not found',
            path: ctx.route.fullPath,
            statusCode: 404,
          });
        }
        checkTags.push({
          name: item?.area,
          type: 'province',
          id: item?.id,
        });
      }
      console.log('1075-', provinceCityList);
      if (city > 0) {
        const item = provinceCityList.find((item: any) => item.id == city);
        if (!item) {
          return ctx.error({
            message: 'Page not found',
            path: ctx.route.fullPath,
            statusCode: 404,
          });
        }
        checkTags.push({
          name: item?.area,
          type: 'province',
          id: item?.id,
        });
      }
      if (+date > 0) {
        // 已选时间
        const item = dateList.find((item: any) => item.value === date);
        checkTags.push({
          name: item?.label || moment(date).format('YYYY年MM月'),
          type: 'date',
          id: item?.value || date,
        });
      }

      if (keyword) {
        checkTags.push({
          name: keyword,
          type: 'keyword',
          id: keyword,
        });
      }

      //

      // 内链
      let innerChainActive: number = 0;
      let paramsIndustry: any[] = [];
      if (secondIndustryId) paramsIndustry.push(secondIndustryId);
      if (firstIndustryId) paramsIndustry.push(firstIndustryId);
      const innerChainAllRes: any = await Promise.all([
        ctx.$axios.post('/api/innerChain/page', {
          category: '热门地区',
          pageSize: 16,
          // locale: detail.locale,
        }),
        ctx.$axios.post('/api/innerChain/page', {
          category: '热门行业',
          industryIdList: paramsIndustry,
          pageSize: 16,
          // locale: detail.locale,
        }),
        ctx.$axios.post('/api/innerChain/page', {
          category: '热门展会',
          industryIdList: paramsIndustry,
          pageSize: 16,
          // locale: detail.locale,
        }),
      ]);

      const [innerChainAreaRes, innerChainIndustryRes, innerChainExhRes] =
        innerChainAllRes;
      let innerChainAreaList: any[] = [];
      let innerChainIndustryList: any[] = [];
      let innerChainExhList: any[] = [];

      if (innerChainAreaRes.code === ResponseCode.SUCCESS) {
        innerChainAreaList = innerChainAreaRes.data.records;
      }
      if (innerChainIndustryRes.code === ResponseCode.SUCCESS) {
        innerChainIndustryList = innerChainIndustryRes.data.records;
        let len = innerChainIndustryList.length;
        if (len < 16) {
          let ctxIndustry: any = await Promise.all([
            ctx.$axios.post('/api/innerChain/page', {
              category: '热门行业',
              pageSize: 16 - len,
            }),
          ]);

          const [innerChainArea2Res] = ctxIndustry;
          if (innerChainArea2Res.code === ResponseCode.SUCCESS) {
            innerChainIndustryList = innerChainIndustryList.concat(
              innerChainArea2Res.data.records
            );
          }
        }
      }
      if (innerChainExhRes.code === ResponseCode.SUCCESS) {
        innerChainExhList = innerChainExhRes.data.records;
        let len = innerChainExhList.length;
        if (len < 16) {
          let ctxIndustry: any = await Promise.all([
            ctx.$axios.post('/api/innerChain/page', {
              category: '热门展会',
              pageSize: 16 - len,
            }),
          ]);

          const [innerChainExh2Res] = ctxIndustry;
          if (innerChainExh2Res.code === ResponseCode.SUCCESS) {
            innerChainExhList = innerChainExhList.concat(
              innerChainExh2Res.data.records
            );
          }
        }
      }

      let interfaceResponseTime = ctx.store.state.global.interfaceResponseTime;
      console.log('asyncData-end', interfaceResponseTime);
      // 设置一个自定义响应头(nuxtTraceId 记录日志位置)
      if (ctx.res) ctx.res.setHeader('nuxtTraceId', interfaceResponseTime);
      if (ctx.$winstonLog) ctx.$winstonLog.info(interfaceResponseTime);
      ctx.store.commit('global/setInterfaceResponseTime', '');

      return {
        lang,
        langApi,
        keyword,
        pageNum,
        pageSize,
        isRecommond,
        hotFair,
        fair,
        industry,
        secondIndustry,
        areaList: areas, // 国内-省份列表
        cityList,
        provinceCityList, // 国内-省份下的城市列表
        dateList,
        firstIndustryId,
        secondIndustryId: +secondIndustryId,
        area: +area,
        province: +province,
        city: +city,
        date,
        type: keyword ? -1 : type,
        checkTags,
        filter: listParams,
        news,
        sortType,
        banner,
        newsList,
        innerChainActive,
        innerChainAreaList,
        innerChainIndustryList,
        innerChainExhList,
      };
    } catch (e) {
      ctx.error(e as any);
    }
  },
  data(): Record<string, any> {
    return {
      keyword: '',
      keywordError: false,
      keywordPlaceholder: '国家，城市，行业，展会名称',
      firstIndustryId: 0,
      secondIndustryId: 0,
      type: 0,
      area: 0,
      province: 0,
      city: 0,
      date: '0',
      typeList: [
        {
          label: this.$t('exhibition.filters.domesticExh'),
          value: '0',
        },
        {
          label: this.$t('exhibition.filters.international'),
          value: '1',
        },
      ],
      value: '',
      fair: {},
      industry: [],
      secondIndustry: [],
      areaList: [],
      cityList: [],
      dateList: [],
      checkTags: [], //  已选条件
      moreArea: false, // 地区更多
      moreCity: false, // 城市更多
      moreDate: false, // 时间更多
      activeSort: this.$route.query.sort,
      scale: {
        sortStr: 'scale',
        sortType: 'asc',
      },
      hot: {
        sortStr: 'hot',
        sortType: 'asc',
      },
      start_time: {
        sortStr: 'start_time',
        sortType: 'asc',
      },
      hotCityList: [
        {
          name: '北京',
          areaId: 1,
          regionId: 11,
          cityId: 1051,
        },
        {
          name: '天津',
          areaId: 1,
          regionId: 523,
          cityId: 1052,
        },
        {
          name: '上海',
          areaId: 1,
          regionId: 519,
          cityId: 1123,
        },
        {
          name: '广州',
          areaId: 1,
          regionId: 528,
          cityId: 1250,
        },
        {
          name: '深圳',
          areaId: 1,
          regionId: 528,
          cityId: 1252,
        },
        {
          name: '重庆',
          areaId: 1,
          regionId: 524,
          cityId: 1305,
        },
        // {
        //   name: '香港',
        //   areaId: 1,
        //   regionId: 11,
        //   cityId: 2072,
        // },
        // {
        //   name: '澳门',
        //   areaId: 1,
        //   regionId: 11,
        //   cityId: 2072,
        // },
      ],
    };
  },
  computed: {
    areas(): any[] {
      if (this.moreArea) {
        if (this.area === 0) {
          return this.areaList;
        }
        return this.cityList;
      } else {
        if (this.area === 0) {
          return this.$data.areaList?.slice(0, 8) || [];
        }
        return this.cityList?.slice(0, 8) || [];
      }
    },
    // 城市列表
    computedProvinceCityList(): any[] {
      if (this.moreCity) {
        return this.provinceCityList;
      } else {
        return this.provinceCityList?.slice(0, 30) || [];
      }
    },
    dates() {
      if (this.moreDate) {
        return this.$data.dateList;
      } else {
        return this.$data.dateList.slice(0, 6);
      }
    },
    newUrl() {
      console.log('1472-');
      return (item: any) => {
        console.log('1474-', item);
        let { firstIndustryId, secondIndustryId, area, province, city, date } =
          this;

        switch (item.type) {
          case 'firstIndustry':
            firstIndustryId = 0;
            secondIndustryId = 0;
            break;
          case 'secondIndustry':
            secondIndustryId = 0;
            break;
          case 'area':
            province = 0;
            city = 0;
            if (area === 1) {
              area = 1;
            } else {
              area = 0;
            }
            break;
          case 'province':
            province = 0;
            city = 0;
            break;
          case 'city':
            city = 0;
            break;
          case 'date':
            date = 0;
            break;
          default:
            return '/exhibition/';
        }

        if (
          firstIndustryId == 0 &&
          secondIndustryId == 0 &&
          area == 0 &&
          province == 0 &&
          city == 0 &&
          date == 0 &&
          this.pageNum == 1
        ) {
          return '/exhibition/';
        }
        return `/exhibition-${firstIndustryId}-${secondIndustryId}-${area}-${province}-${city}-${date}-1/`;
      };
    },
  },
  created(): void {
    if (this.$route.query.type === 'hot') {
      this.activeSort = 'hot';
    }
    // 本地
    // console.log(1073,this.$route)
    // const {
    //     f: firstIndustryId = '0',
    //     s: secondIndustryId = 0,
    //     g: area = 0,
    //     p: province = 0,
    //     c: city = 0,
    //     t: date = '0',
    //     page = 1
    //   } = this.$route.params

    // console.log(1083, firstIndustryId, secondIndustryId)
    // // if (+firstIndustryId > 0) {
    // //     this.$store.dispatch('global/getLinks', { id: firstIndustryId })
    // //   }
    // this.getIndustry()
    // 本地end
  },
  mounted() {
    if (checkDevice() == 'h5') {
      let url = `https://m.jufair.com${this.$route.path}`;
      window.location.replace(url);
    }
  },
  methods: {
    handleNewsUrl,
    handleHotTab(e: any) {
      this.innerChainActive = e;
    },
    handleKeywordInput() {
      const { keyword } = this;
      if (!keyword) {
        this.keywordError = false;
        this.keywordPlaceholder = this.$t('menu.keywordPlaceholder1');
      } else if (keyword.length > 50) {
        this.keywordError = true;
        this.keywordPlaceholder = this.$t('menu.keyword_error2');
      } else {
        this.keywordError = !/^[a-zA-Z\u4E00-\u9FA5 \(\)（）]+$/.test(keyword);
        this.keywordPlaceholder = this.$t('menu.keywordPlaceholder3');
      }
    },
    handleSearch: _.debounce(
      function () {
        // @ts-ignore
        const { keyword, $message } = this;
        // if (!isLogin) {
        //   return $login.show();
        // }
        if (!/^[0-9a-zA-Z\u4E00-\u9FA5 \(\)（）]+$/.test(keyword)) {
          // @ts-ignore
          this.keywordError = true;
          // @ts-ignore
          this.$message({
            type: 'error',
            // @ts-ignore
            message: this.$t('menu.keyword_error1'),
            offset: 500,
          });
          return;
        } else {
          // @ts-ignore
          this.keywordError = false;
        }
        if (keyword.length > 50) {
          // @ts-ignore
          this.keywordError = true;
          // @ts-ignore
          this.$message({
            type: 'error',
            // @ts-ignore
            message: this.$t('menu.keyword_error2'),
            offset: Math.ceil(window.innerHeight / 2),
          });
          return;
        }
        if (keyword) {
          window.open(`/exhibition/?keyword=${keyword}`, '_blank');
        } else {
          // @ts-ignore
          $message.warning(<string>this.$t('menu.keywordPlaceholder1'));
        }
      },
      500,
      { leading: true }
    ),
    // handleFirstIndustryChange(industryId: number) {
    //   const item: any = this.industry.find(
    //     (item: any) => item.industryId === industryId
    //   );
    //   this.secondIndustry = item.children;
    // },
    brandName(tags: any[]): string {
      const obj: Record<string, any> = {};
      tags.forEach((tag) => {
        obj[tag.type] = tag.name;
      });

      let name = '';
      if (obj.date) {
        name += obj.date;
      }

      if (obj.province) {
        name += obj.province;
      } else if (obj.area) {
        name += obj.area;
        // name += obj.area == '中国' ? '国内' : obj.area;
      }

      if (obj.secondIndustry) {
        name += obj.secondIndustry;
      } else if (obj.firstIndustry) {
        name += obj.firstIndustry;
      }

      return `${name}`;
    },
    /**
     * 获取全部行业
     */
    getIndustry() {
      this.$axios
        .get(`/api/industry/getAll?locale=${this.langApi}`)
        .then((res: any) => {
          console.log('获取行业-1087', res);
          if (res.code == 1) {
            this.industry = res.data;

            let params = this.$route.params;
            // let firstIndustryId = Number(params.f)
            if (this.firstIndustryId > 0) {
              // 已选一级行业
              const item = this.industry.find(
                (item: any) => item.industryId == this.firstIndustryId
              );
              this.secondIndustry = item?.children; // 二级行业
              this.checkTags.push({
                name: item?.industryName,
                type: 'firstIndustry',
                id: this.firstIndustryId,
              });
            }
            if (this.secondIndustryId > 0) {
              // 已选二级行业
              const item = this.secondIndustry?.find(
                (item: any) => item.id == this.secondIndustryId
              );
              if (!item) {
                this.$message.error('二级行业不存在');
                return;
              }
              this.checkTags.push({
                name: item?.industryName,
                type: 'secondIndustry',
                id: this.secondIndustryId,
                pid: this.firstIndustryId,
              });
            }
          }
        });
    },
    // 地址更多
    handleAreaMore() {
      this.moreArea = !this.moreArea;
    },
    // 城市更多
    handleCityMore() {
      this.moreCity = !this.moreCity;
    },
    // 时间更多
    handleDateMore() {
      this.moreDate = !this.moreDate;
    },
    // 获取展会列表
    handleGetFairList({ sortStr, sortType }: Record<string, any>) {
      const params: any = _.cloneDeep(this.$route.query);

      const sort = this.sortType === 'desc' ? 'asc' : 'desc';
      if (sortStr && sortType) {
        this.activeSort = sortStr;
        this[sortStr].sortType = sort;

        params.sort = sortStr;
        params.type = sort;
      }
      // window.location.href = `${this.$route.path}?${qs.stringify(params)}`
      // 重置页数为第1页
      window.location.href = `/exhibition-${this.firstIndustryId}-${
        this.secondIndustryId
      }-${this.area}-${this.province}-${this.city}-${
        this.date
      }-1/?${qs.stringify(params)}`;
      // this.$router.push(`${this.$route.path}?${qs.stringify(params)}`);

      // const res: any = await this.$axios.post('/api/exhibition/list', params);
      // if (res.code === ResponseCode.SUCCESS) {
      //   this.fair = res.data;
      // }
    },
    // 发布展会
    handleRelease() {
      const access_token = $cookies.get('access_token');
      // 获取不到token为未登录, 前往登录页
      if (!access_token) {
        this.$login.show();
        // this.$router.push({
        //   path: `/login?redirect=${this.$route.fullPath}`,
        // });
        return;
      }
      window.open(`${adminHost}?token=${access_token}`, '_blank'); // TODO url: 填写主办方发布展会页面
    },
    changePager(page: number) {
      const { firstIndustryId, secondIndustryId, area, province, city, date } =
        this;

      this.$router.push({
        path: `/exhibition-${firstIndustryId}-${secondIndustryId}-${area}-${province}-${city}-${date}-${page}/`,
        query: this.$route.query,
      });
    },
    areaChange(value: number) {
      console.log('1867-', value);
      this.queryAreaList(value);
    },
    async queryAreaList(id: number) {
      console.log('1870-', id, +id);
      if (+id > 1) {
        const area: any = this.$data.areaList.find(
          (item: any) => item.id === +id
        );
        this.$data.cityList = area.areas;
      } else if (+id === 1) {
        const res: any = await this.$axios.get('/api/exhibition/city/0');
        if (res.code === ResponseCode.SUCCESS) {
          this.$data.cityList = _.get(res, 'data[0].areas');
        }
      }
    },
  },
  head(): Record<string, any> {
    const {
      f: firstIndustryId,
      s: secondIndustryId,
      g: continentId,
      p: countryId,
      c: cityId,
      t: date,
      page,
    }: any = this.$route.params;
    const { keyword = '' } = this.$route.query;

    let title = '';
    let keywords = '';
    let description = '';

    let pageText = '';
    let _year = 0;
    let _month = 0;
    let _date = '';
    const year = moment().year();
    if (date > 0) {
      _year = moment(`${date}01`).year();
      _month = moment(`${date}01`).month() + 1;
      _date = `${_year}年${_month}月`;
    }

    let firstIndustry: Record<string, any> = {}; // 一级行业
    let secondIndustry: Record<string, any> = {}; // 二级行业
    let continent: Record<string, any> = {}; // 洲,国
    let city: Record<string, any> = {}; // 城市

    if (firstIndustryId > 0) {
      firstIndustry = this.$data.industry.find(
        (item: { industryId: string }) => item.industryId == firstIndustryId
      );
    }

    if (secondIndustryId > 0) {
      secondIndustry = firstIndustry.children.find(
        (item: { id: string }) => item.id == secondIndustryId
      );
    }

    if (continentId > 0) {
      continent = this.$data.areaList.find(
        (item: { id: string }) => item.id == continentId
      );
    }

    if (countryId > 0) {
      city = continent.areas.find(
        (item: { id: string }) => item.id == countryId
      );
    }

    const address = city.area || continent.regionName || '';
    if (page > 1) {
      pageText = `_第${page}页`;
    }
    // 国内
    if (this.type == 0) {
      title = `国内展会_中国展会_中国博览会_中国交易会_${year}年中国展览会信息${pageText}-聚展`;
      keywords = `国内展会,中国展会,中国会展,中国展会信息,${keyword}`;
      description = `聚展网为您提供${year}最新最全的中国展,中国展会排期,中国展览会信息及资讯,展会门票,展位预订,博览会门票,展会时间,是您参展中国展会最好的展会服务平台`;
    } else {
      title = `国际展会_国际博览会_国际展览会信息服务平台${keyword}${pageText}-聚展`;
      keywords = `国际展会,国际展会信息,国外展会,国际展览会,国外展览会,${keyword}`;
      description =
        '聚展网为您提供最新国际展览会,国外展会,国际会展中心展会信息,为您提供参展选展最佳指导方案,国际展览会中心最新展会资讯';
    }
    // 地区
    if (address) {
      title = `${address}展会_${year}年${address}展览会_时间_门票${pageText}-聚展`;
      keywords = `${address}展会,${address}展览会,${address}博览会,${address}交易会`;
      description = `聚展网提供${year}最新最全的${address}展,${address}展会排期,${address}展览会信息及资讯,展会门票,展位预订,博览会门票,展会时间,是您参展${address}展会最好的展会服务平台`;
    }
    // 时间
    if (date > 0) {
      const _year = moment(`${date}01`).year();
      const _month = moment(`${date}01`).month() + 1;
      const _date = `${_year}年${_month}月`;
      const _address = this.type === 0 ? '中国' : '国际';
      const _prefix = `${_date}${_address}`;

      title = `${_prefix}展会信息_${_address}博览会_${_address}交易会${pageText}-聚展`;
      keywords = `${_prefix}展览会,${_prefix}展会时间,${_prefix}展会地点`;
      description = `聚展网为您提供${_prefix}展会信息,${_date}展会排期,展会门票,为您参展选展提供最佳指导方案`;
    }
    // 一级行业
    switch (firstIndustryId) {
      case '16': // 车／配件
        title = `${_date}${address}车展_汽配摩配展_电动车展_自行车展_改装车展_${keyword}${pageText}-聚展`;
        keywords = `${address}车博览会,${address}汽配摩配博览会,电动车博览会,自行车博览会,改装车博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${year}最新最全展览会资讯`;
        break;
      case '19': // 五金/建材
        title = `${_date}${address}五金展_建材展_暖通制冷展_地铺展_玻璃展${keyword}${pageText}-聚展`;
        keywords = `${address}五金博览会,${address}建材博览会,暖通制冷博览会,地铺博览会,玻璃博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${year}最新最全展览会资讯`;
        break;
      case '26': // 工业/机械
        title = `${_date}${address}工业展_工程机械展_机器人展_木工展_自动化展${keyword}${pageText}-聚展`;
        keywords = `${address}工业博览会,${address}工程机械博览会,机器人博览会,木工博览会,自动化博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${year}最新最全展览会资讯`;
        break;
      case '27': // 电子/电力
        title = `${_date}${address}电子展_电力展_照明展_光电展_灯光舞台展${keyword}${pageText}-聚展`;
        keywords = `${address}电子博览会,${address}电力博览会,照明博览会,光电博览会,灯光舞台博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${year}最新最全展览会资讯`;
        break;
      case '28': // 通信/网络
        title = `${_date}${address}通讯展_通信展_游戏展_智能识别展_互联网展${keyword}${pageText}-聚展`;
        keywords = `${address}通讯博览会,${address}通信博览会,游戏博览会,智能识别博览会,互联网博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${year}最新最全展览会资讯`;
        break;
      case '29': // 能源化工
        title = `${_date}${address}能源展_化工展_太阳能展_环保展_电池展${keyword}${pageText}-聚展`;
        keywords = `${address}能源博览会,${address}化工博览会,太阳能博览会,环保博览会,电池博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${year}最新最全展览会资讯`;
        break;
      case '30': // 交通航天
        title = `${_date}${address}交通展_航空航天展_运输物流展_船舶海事展${keyword}${pageText}-聚展`;
        keywords = `${address}交通博览会,${address}航空航天博览会,运输物流博览会,船舶海事博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${year}最新最全展览会资讯`;
        break;
      case '31': // 安防劳保
        title = `${_date}${address}安防展_劳保展_防务展_军警展_公共安全展${keyword}${pageText}-聚展`;
        keywords = `${address}安防博览会,${address}劳保博览会,防务博览会,军警博览会,公共安全博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${year}最新最全展览会资讯`;
        break;
      case '32': // 纺织服装
        title = `${_date}${address}纺织面料服装展_鞋包展_珠宝钟表展_箱包皮革展${keyword}${pageText}-聚展`;
        keywords = `${address}纺织面料服装博览会,${address}鞋包博览会,珠宝钟表博览会,箱包皮革博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${year}最新最全展览会资讯`;
        break;
      case '33': // 生物医疗
        title = `${_date}${address}生物制药展_医疗设备展_美容美发展_成人用品展${keyword}${pageText}-聚展`;
        keywords = `${address}生物制药博览会,${address}医疗设备博览会,美容美发博览会,成人用品博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${year}最新最全展览会资讯`;
        break;
      case '34': // 消费家电
        title = `${_date}${address}日用消费品展_礼品展_家具展_消费电子展${keyword}${pageText}-聚展`;
        keywords = `${address}日用消费品博览会,${address}礼品博览会,家具博览会,消费电子博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${year}最新最全展览会资讯`;
        break;
      case '35': // 旅游户外
        title = `${_date}${address}户外体育用品展_实验仪器展_旅游用品展_教育展${keyword}${pageText}-聚展`;
        keywords = `${address}户外体育用品博览会,${address}实验仪器博览会,旅游用品博览会,教育博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${year}最新最全展览会资讯`;
        break;
      case '36': // 广告影视
        title = `${_date}${address}印刷包装展_纸业展_游戏娱乐展_影视器材展${keyword}${pageText}-聚展`;
        keywords = `${address}印刷包装博览会,${address}纸业博览会,游戏娱乐博览会,影视器材博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${year}最新最全展览会资讯`;
        break;
      case '37': // 办公婴童
        title = `${_date}${address}办公文具展_婴童用品展_玩具展_宠物展_${keyword}${pageText}-聚展`;
        keywords = `${address}办公文具博览会,${address}婴童用品博览会,玩具博览会,宠物博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${year}最新最全展览会资讯`;
        break;
      case '38': // 农业牧业
        title = `${_date}${address}农业展_畜牧展_饲料展_园林园艺展_渔业展_${keyword}${pageText}-聚展`;
        keywords = `${address}农业博览会,${address}畜牧博览会,饲料博览会,园林园艺博览会,渔业博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${year}最新最全展览会资讯`;
        break;
      case '39': // 食品生鲜
        title = `${_date}${address}食品饮料展_烟草展_酒类展_果蔬展_咖啡茶展_${keyword}${pageText}-聚展`;
        keywords = `${address}食品饮料博览会,${address}烟草博览会,酒类博览会,果蔬博览会,咖啡茶博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${year}最新最全展览会资讯`;
        break;
    }
    // 二级行业
    if (secondIndustryId > 0) {
      const name = secondIndustry?.industryName;

      title = `${name}展会_${year}年国际${name}展览会_${name}博览会${pageText}-聚展`;
      keywords = `${name}展门票,${name}展会时间,${name}展览会地点,${name}博览会展位,${name}展会信息`;
      description = `聚展网为您提供最新${name}展会排期表,${name}展会信息,${name}展会门票,${name}展会会刊,${name}展会展位预定,专业的一站式展会服务平台`;
    }
    // 地区+二级行业
    if (address && secondIndustryId > 0) {
      const secondIndustryName = secondIndustry?.industryName;
      const _preFix = `${address}${secondIndustryName}`;

      const ext = this.type == 0 ? '' : '_邀请函';
      title = `${_preFix}展会有哪些?_${_preFix}展会信息_展商信息${ext}-聚展`;
    }
    // 城市+二级行业
    if (cityId > 0 && secondIndustryId > 0) {
      const secondIndustryName = secondIndustry?.industryName;
      const _preFix = `${address}${secondIndustryName}`;

      title = `${_preFix}展_${_preFix}博览会信息${pageText}-聚展`;
      keywords = '';
      description =
        '聚展网为您提供最新国际展览会,国外展会,国际会展中心展会信息,为您提供参展选展最佳指导方案,国际展览会中心最新展会资讯';
    }
    // 时间+二级行业
    if (date > 0 && secondIndustryId > 0) {
      const _year = moment(`${date}01`).year();
      const _month = moment(`${date}01`).month() + 1;
      const _date = `${_year}年${_month}月`;
      const secondIndustryName = secondIndustry?.industryName;

      title = `${secondIndustryName}展会_${_date}国际${secondIndustryName}博览会_时间_门票${pageText}${pageText}-聚展`;
      keywords = `${_date}${secondIndustryName}展,${_date}${secondIndustryName}展会,${_date}${secondIndustryName}展览会,${_date}${secondIndustryName}博览会,${_date}${secondIndustryName}展销会,${_date}${secondIndustryName}展会信息`;
      description = `聚展网-提供${_date}${secondIndustryName}展会排期,${_date}${secondIndustryName}展会信息,${_date}${secondIndustryName}展会时间,${_date}${secondIndustryName}展会门票,为您出国参加${secondIndustryName}展会更加方便的展会网-专注打造一站式展会网络服务平台`;
    }
    // 时间+地区+国际展会
    if (date > 0 && address && +firstIndustryId === 0) {
      const _year = moment(`${date}01`).year();
      const _month = moment(`${date}01`).month() + 1;
      const _date = `${_year}年${_month}月`;
      const _prefix = `${_date}${address}`;

      title = `${address}展会_${_prefix}博览会_时间_门票${pageText}-聚展`;
      keywords = `${_prefix}展,${_prefix}展会,${_prefix}展览会,${_prefix}博览会,${_prefix}展销会,${_prefix}展会信息`;
      description = `聚展网-提供${_prefix}展会排期,${_prefix}展会信息,${_prefix}展会时间,${_prefix}展会门票,为您出国参加展会更加方便的展会网-专注打造一站式展会网络服务平台`;
    }
    // 时间+地区+二级行业
    if (date > 0 && address && secondIndustryId > 0) {
      const _year = moment(`${date}01`).year();
      const _month = moment(`${date}01`).month() + 1;
      const _date = `${_year}年${_month}月`;
      const secondIndustryName = secondIndustry?.industryName;
      const _prefix = `${_date}${address}${secondIndustryName}`;

      title = `${address}${secondIndustryName}展会_${_prefix}博览会_时间_门票${pageText}-聚展`;
      keywords = `${_prefix}展,${_prefix}展会,${_prefix}展览会,${_prefix}博览会,${_prefix}展销会,${_prefix}展会信息`;
      description = `聚展网-提供${_prefix}展会排期,${_prefix}展会信息,${_prefix}展会时间,${_prefix}展会门票,为您出国参加${secondIndustryName}展会更加方便的展会网-专注打造一站式展会网络服务平台`;
    }
    // 右上角输入框搜索内容
    if (keyword) {
      title = `${keyword}${pageText}-聚展`;
      keywords = `${keyword},展览会,博览会,交易会,展会时间,展会地点,展会门票`;
      description = `聚展网为您提供${year}最新最全的${keyword},${keyword}展会排期,${keyword}展览会信息及资讯,是您参展${keyword}展会最好的展会服务平台`;
    }

    return {
      title,
      meta: [
        {
          name: 'mobile-agent',
          'http-equiv': 'mobile-agent',
          content: `format=html5; url=https://m.jufair.com/exhibition-${firstIndustryId}-${secondIndustryId}-${continentId}-${countryId}-${cityId}-${date}-${page}`,
        },
        {
          name: 'keywords',
          content: keywords,
        },
        {
          name: 'description',
          content: description,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: `https://www.jufair.com/exhibition-${firstIndustryId}-${secondIndustryId}-${continentId}-${countryId}-${cityId}-${date}-1/`,
        },
      ],
    };
  },
});
